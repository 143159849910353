import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AlertButtons from './AlertButtons';
import styles from './AlertsContainer.module.scss';

const Alert = ({ closeLabel, onClose, buttonLabel, onButtonClick, messages, isReferral }) => {
  if (!messages?.length) {
    return null;
  }

  return (
    <div role="alert" className={classnames(styles.alert, { [styles.referralBanner]: isReferral })}>
      <ul className={styles.alertMessages}>
        {messages
          ?.sort((a, b) => b.length - a.length)
          .map((alert, index) => (
            <li
              key={alert}
              className={classnames(
                styles.message,
                { [styles.referralMessage]: isReferral },
                { [styles.firstMessage]: index === 0 }
              )}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: alert }}
            />
          ))}
      </ul>
      <AlertButtons
        closeLabel={closeLabel}
        onClose={onClose}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
        isReferral={isReferral}
      />
    </div>
  );
};

Alert.propTypes = {
  onClose: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  closeLabel: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
  isReferral: PropTypes.bool.isRequired,
};

export default Alert;
