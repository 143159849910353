import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AppName from 'components/AppName';
import AccessMessage from 'components/AccessMessage';
import NavLayout from 'components/NavLayout';
import { openUmaiLink } from 'util/url';
import styles from './Subscription.module.scss';

function Subscription() {
  const [t] = useTranslation();

  return (
    <NavLayout showAppName={false} title="Subscription">
      <AccessMessage
        header={<AppName type="theme" />}
        title={t('access-message.title')}
        description={
          <div className={styles.description}>
            <ul>
              <Trans i18nKey="access-message.description">
                <li>
                  Create loyal returning guests with a market leading Loyalty system that can be
                  customized to suit your needs.
                </li>
                <li>
                  Use your existing guest database and combine it with UMAI Loyalty to get even more
                  benefits.
                </li>
                <li>
                  UMAI Loyalty sends out automated marketing messages to your guests based on their
                  visiting habits, birthdays, spend, and more.
                </li>
                <li>
                  Tailor the messages to fit your business & branding. Decide whether you want to
                  add an incentive or not, and choose what communication channels you want to use.
                </li>
              </Trans>
            </ul>
          </div>
        }
        subTitle={t('access-message.subtitle')}
        footer={
          <button
            type="button"
            onClick={() => {
              openUmaiLink();
            }}
          >
            {t('access-message.footer')}
          </button>
        }
      />
    </NavLayout>
  );
}

export default Subscription;
