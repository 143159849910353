import { OpenNewWindow } from '@umai/common';
import { parse } from 'util/query-string';
import URL_PARAM from 'constants/url-param';
import APP_VIEW from 'constants/app-view';
import { HELP_CHAT_INTERCOM_ID, HELP_CENTER_URL, UMAI_WELCOME_URL } from 'constants/app-const';
import { REACT_APP_UMAI360_URL } from 'constants/app-env';

const getURLParams = () => {
  try {
    const parsedParams = parse(window.location.search);
    if (Object.keys(parsedParams).length) return parsedParams;
    return {};
  } catch (e) {
    console.error('Something went wrong when parsing URL params', e);
    return {};
  }
};

export const getViewParam = () => {
  const urlParams = getURLParams();
  if (URL_PARAM.VIEW in urlParams) {
    return urlParams[URL_PARAM.VIEW];
  }
  return undefined;
};

export const getViewFromPath = () => {
  const url = window.location.href;
  if (url.toLowerCase().includes(APP_VIEW.CAPTURE.toLowerCase())) {
    return APP_VIEW.CAPTURE;
  }

  if (url.toLowerCase().includes(APP_VIEW.REPORTS.toLowerCase())) {
    return APP_VIEW.REPORTS;
  }

  return undefined;
};

export const getStepFromURL = () => {
  const urlParams = getURLParams();
  if (Object.prototype.hasOwnProperty.call(urlParams, 'step')) {
    const step = urlParams.step || '';
    return step.toUpperCase();
  }
  return undefined;
};

export const getVenueSlugFromURL = () => {
  const urlPath = window.location.pathname || '';
  const pathParts = urlPath.split('/');

  if (pathParts.length > 4) {
    // splitted path can look like this ["", "venues", "raphaelle-treutel-cafe", "guests", "search"]
    // so venue slug is at index of 2
    if (!pathParts[0].trim().length) {
      return pathParts[2] || '';
    }
    return pathParts[1] || '';
  }
  // if (Object.prototype.hasOwnProperty.call(urlParams, 'slug')) {
  //   const slug = urlParams.slug || '';
  //   return slug.toLowerCase();
  // }
  return undefined;
};

export const getPhoneNumberFromURL = () => {
  const urlParams = getURLParams();
  if (Object.prototype.hasOwnProperty.call(urlParams, 'phone_number')) {
    const phoneNumber = urlParams.phone_number || '';
    return phoneNumber.toLowerCase();
  }
  return undefined;
};

export const getAuthTokenFromURL = () => {
  const urlParams = getURLParams();
  if (Object.prototype.hasOwnProperty.call(urlParams, 'auth_token')) {
    const authToken = urlParams.auth_token || '';
    return authToken;
  }
  return undefined;
};

export const openHelpCenterLink = new OpenNewWindow(HELP_CENTER_URL, HELP_CHAT_INTERCOM_ID);

export const openUmaiLink = new OpenNewWindow('https://umai.io', 'umai-powered-by-umai');

export const openTermAndCondition = new OpenNewWindow(
  'https://www.umai.io/en/terms',
  'loyalty-term-and-condition'
);

export const openPrivacyAndPolicy = new OpenNewWindow(
  'https://www.umai.io/en/privacy',
  'loyalty-privacy-and-policy'
);

export const open360BillingSettings = new OpenNewWindow(
  `${REACT_APP_UMAI360_URL}/settings/billing`,
  'umai360'
);

export const openPoweredByUmai = (venueId, venueName) =>
  new OpenNewWindow(
    `${UMAI_WELCOME_URL}?rid=${venueId ?? ''}&restaurant_name=${encodeURIComponent(
      venueName ?? ''
    )}`,
    'Umai'
  );
