import { css } from '@emotion/react';

export const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

const poweredBy = css`
  text-transform: lowercase;
  font-size: 1rem;
`;

export const poweredByDark = css`
  ${poweredBy};
`;

export const poweredByLight = css`
  ${poweredBy};
  color: white;
`;

export const umaiLogo = css`
  color: #19a69a;
  font-size: 1.35rem;
  font-weight: 350;

  &:hover {
    color: var(--color-primary);
  }
`;
