import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PartnerContext } from 'contexts/PartnerProvider';
import { openPoweredByUmai } from 'util/url';
import * as Styles from './PoweredByUMAI.styles';

const PoweredByUMAI = ({ type = 'dark' }) => {
  const [t] = useTranslation();

  const {
    partner: { currentVenueId, currentVenueName },
  } = useContext(PartnerContext);

  const powerByStyles = type === 'dark' ? Styles.poweredByDark : Styles.poweredByLight;
  return (
    <button
      type="button"
      css={Styles.wrapper}
      onClick={openPoweredByUmai(currentVenueId, currentVenueName)}
    >
      <span css={powerByStyles}>{t('powered-by')}</span>&nbsp;
      <span>
        <span css={Styles.umaiLogo}>UMAI</span>
      </span>
    </button>
  );
};

PoweredByUMAI.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
};

export default PoweredByUMAI;
